<template>
    <section class="choose-profile-type">
            <div class="choose-profile-type-item">
                <img class="select-image" src="@/assets/images/carousel/choose-profile-type.png" alt="">
                <label class="select-description">O primeiro passo é entender o seu perfil, para direcionar você
                    corretamente. Por isso, gostaríamos de saber se você é uma empresa a procura de um profissional ou
                    um profissional a procura de uma oportunidade.</label>
                <div class="buttons">
                    <span class="button-all button-primary">Sou um profissional a procura de uma clínica</span>
                    <span class="button-all button-secondary">Sou uma clínica a procura de um profissional</span>
                </div>
            </div>
        </section>
</template>

<script>
export default {
    setup() {


        return {}
    }
}
</script>

<style lang="scss" scoped></style>