<template>
    <section class="auth-register">
        <div class="auth-splash">
            <div class="splash-circle">
                <img class="logo" src="@/assets/images/logo.png" alt="">
            </div>
        </div>
    </section>
</template>

<script>

export default {
    created(){
        console.log("ok")
        setTimeout(()=> this.$router.push('/auth'), 2000)
    }
}

</script>