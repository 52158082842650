<template>
    <div>
        <div class="modal-all hide">
        <div class="modal-all-box">
            <div class="modal-all-header">
                <div class="modal-all-header-title">Tudo certo!</div>
                <div class="modal-all-header-description">Vamos começar!</div>
            </div>
            <div class="modal-all-body">
                <div class="confirm-img-box">
                    <img class="confirm-img" src="@/assets/images/carousel/confirm-email.png" alt="">
                </div>
            </div>
            <div class="modal-all-footer">
                <span class="button-all">Continuar</span>
            </div>
        </div>
    </div>
    <section class="confirm-code">
        <div class="arrow-back" @click="$router.push('/auth')">
            <img src="@/assets/images/arrow_back.png" alt="">
        </div>
        <div class="confirm-code-form">
            <div class="confirm-code-header">
                <label class="header-title">Verificação de e-mail</label>
                <p class="header-description">Sua segurança é muito importante!</p>
            </div>
            <div class="confirm-code-box">
                <label class="confirm-code-box-description">
                    Insira o código que enviamos para o e-mail <span class="button-link">exemplo@exemplo.com.br</span>
                </label>
                <div class="confirm-code-input">
                    <input type="text" maxlength="1" class="digit" />
                    <input type="text" maxlength="1" class="digit" />
                    <input type="text" maxlength="1" class="digit" />
                    <input type="text" maxlength="1" class="digit" />
                    <input type="text" maxlength="1" class="digit" />
                    <input type="text" maxlength="1" class="digit" />
                  </div>
                  <label class="resend-email">
                    Não recebeu o código?
                    <span class="button-link">Reenviar</span>
                  </label>
                  <span class="button-all button-primary">Verificar</span>
            </div>
        </div>
    </section>
    </div>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>