<template>
    <section class="auth-register">
        <span class="loading hide">
            <div class="spinner"></div>
        </span>

        <div class="arrow-back" @click="$router.push('/auth')">
            <img src="@/assets/images/arrow_back.png" alt="">
        </div>
        <div class="login-register register">
            <div class="auth-login-header">
                <label class="header-title">Novo cadastro</label>
                <p class="header-description">Precisamos te identificar quando você vier!</p>
            </div>
            <div class="box-form">
                <div class="group-input">
                    <input class="input" id="name" type="email" required>
                    <label class="text" for="name">Nome</label>
                </div>
                <div class="group-input">
                    <input class="input" id="email" type="email" required>
                    <label class="text" for="email">E-mail</label>
                </div>
                <div class="group-input">
                    <span class="view-text" @click="toggleVisibility(1)">
                        <i class="material-icons-outlined">{{ (showPassword)? 'visibility' : 'visibility_off' }}</i> 
                    </span>
                    <input class="input password" id="password" :type="inputType" v-model="password" required>
                    <label class="text" for="password">Senha</label>
                </div>
                <div class="group-input-description">
                    <p>Mínimo 08 caracteres</p>
                    <p>Máximo 12 caracteres</p>
                    <p>Ao menos 01 letra maiúscula</p>
                    <p>Ao menos 01 letra minúscula</p>
                    <p>Ao menos 01 caractere especial (!@#$%)</p>
                    <p>Confirmação de senha</p>
                </div>
                <div class="group-input">
                    <span class="view-text" @click="toggleVisibility(0)">
                        <i class="material-icons-outlined">{{ (showRePassword)? 'visibility' : 'visibility_off' }}</i> 
                    </span>
                    <input class="input password" id="password" :type="inputReType" v-model="re_password" required>
                    <label class="text" for="password">Confirmação de senha</label>
                </div>
                <div class="auth-login-check remember">
                    <div class="checkbox-input">
                        <input class="action-checkbox-input" type="checkbox" id="checkbox-input">
                        <label class="box-checkbox-input" for="checkbox-input">
                            <svg width="12" height="9" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z" fill="#fff"/>
                            </svg>
                        </label>
                        <label class="text">Li e aceito os <span class="button-link">Termos e condições</span></label>
                    </div>
                </div>
                <router-link class="button-all button-primary" to="/verificar-email">Finalizar cadastro</router-link>
            </div>
            <div class="auth-login-social">
                <label class="text-division">Ou cadastre-se com</label>
                <div class="social-list">
                    <div class="social-item">
                        <img class="social-icon" src="@/assets/images/google-icon.png" alt="">
                    </div>
                    <div class="social-item">
                        <img class="social-icon" src="@/assets/images/apple-icon.png" alt="">
                    </div>
                </div>
                <div class="register">
                    Já tem uma conta?
                    <router-link class="button-link" to="/auth">Faça login</router-link>
                </div>
            </div>   
        </div>
    </section>
</template>

<script>
export default {
    data () {
        return {
            showPassword: false,
            showRePassword: false,
            inputType: 'password',
            inputTypeRe: 'password'
        }
    },
    methods: {
        toggleVisibility(type) {

            if(type == 1){
                this.showPassword = !this.showPassword;
                this.inputType = this.showPassword ? 'text' : 'password'

                return;
            } 
            this.showRePassword = !this.showRePassword;
            this.inputReType = this.showRePassword ? 'text' : 'password';
        },
    }
}
</script>
