<template>
    <section class="auth-register">
        <div v-show="!isStep" class="auth-carousel" id="auth-carousel">
            <div class="auth-carousel-jump cursor-pointer" @click="stepAuth" role="button">Pular</div>
            <swiper :modules="modules" :pagination="pagination" :navigation="navigation" class="mySwiper">
                <swiper-slide>
                    <div class="auth-carousel-item">
                        <img class="carousel-image" src="@/assets/images/carousel/carousel-2.png" alt="">
                        <label class="carousel-description">
                            As melhores conexões, sempre <span>próximo</span> de você!
                        </label>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="auth-carousel-item">
                        <img class="carousel-image" src="@/assets/images/carousel/carousel-1.png" alt="">
                        <label class="carousel-description">
                            Encontre a <span>conexão profissional</span> que você procura!
                        </label>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="auth-carousel-item">
                        <img class="carousel-image" src="@/assets/images/carousel/carousel-3.png" alt="">
                        <label class="carousel-description">
                            <span>Facilidade</span> e <span>praticidade</span> na conexão!
                        </label>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="auth-carousel-step">
                <span class="carousel-step prev">
                    <img src="@/assets/images/step-prev.png" @click="stepByStep(0)">
                </span>
                <span class="carousel-steps-point"></span>
                <span class="carousel-step next">
                    <img src="@/assets/images/step-next.png" @click="stepByStep(1)">
                </span>
            </div>
        </div>

        <div v-show="isStep" class="login-register login" id="login-register">
            <div class="auth-login-header">
                <label class="header-title">Login</label>
                <p class="header-description">Quem bom que você está aqui! Sentimos sua falta!</p>
            </div>
            <div class="box-form">
                <div class="group-input">
                    <input class="input" id="email" type="email" required>
                    <label class="text" for="email">E-mail</label>
                </div>
                <div class="group-input">
                    <span class="view-text" @click="toggleVisibility">
                        <i class="material-icons-outlined">{{ (showPassword)? 'visibility' : 'visibility_off' }}</i> 
                    </span>
                    <input class="input password" id="password" :type="inputType" v-model="password" required>
                    <label class="text" for="password">Senha</label>
                </div>
                <div class="auth-login-check remember">
                    <div class="checkbox-input">
                        <input class="action-checkbox-input" type="checkbox" id="checkbox-input">
                        <label class="box-checkbox-input" for="checkbox-input">
                            <svg width="12" height="9" viewBox="0 0 12 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z" fill="#fff" />
                            </svg>
                        </label>
                        <label class="text">Lembrar minha senha</label>
                    </div>
                    <router-link to="recuperar-senha" class="button-link forgot-password">Recuperar senha</router-link>
                </div>
                <router-link to="/selecionar-registro" class="button-all button-primary">Entrar</router-link>
            </div>
            <div class="auth-login-social">
                <label class="text-division">Ou faça login com</label>
                <div class="social-list">
                    <div class="social-item">
                        <img class="social-icon" src="@/assets/images/google-icon.png" alt="">
                    </div>
                    <div class="social-item">
                        <img class="social-icon" src="@/assets/images/apple-icon.png" alt="">
                    </div>
                </div>
                <div class="register">
                    Não tem uma conta?
                    <router-link class="button-link" to="/cadastro">Criar conta</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';

import 'swiper/css/pagination';

import { Pagination, Navigation } from 'swiper/modules';

export default {
    data(){
        return {
            step: 0,
            isStep: true,
            showPassword: false,
            inputType: 'password',
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Pagination, Navigation],
            pagination: {
                el: ".carousel-steps-point",
                clickable: true,
            },
            navigation: {
                nextEl: ".carousel-step.next",
                prevEl: ".carousel-step.prev",
            },
        };
    },
    methods: {
        stepAuth(){
            this.isStep = true;
        },

        stepByStep(type){

            if(type == 1){
                this.step += 1
            } else{
                this.step -= 1 
            }

            if(this.step < 3) return;
            this.stepAuth();
        },
        toggleVisibility() {
            this.showPassword = !this.showPassword;
            this.inputType = this.showPassword ? 'text' : 'password' 
        },
    }
};
</script>