import { createRouter, createWebHistory } from 'vue-router'
import SplashView from '@/views/SplashView.vue'
import AuthView from '@/views/AuthView.vue'
import RegisterView from '@/views/RegisterView.vue'
import ForgotPasswordView from '@/views/ForgotPasswordView.vue'
import ConfirmCodeView from '@/views/ConfirmCodeView.vue'
import SelectRegister from '@/views/selectRegister.vue'

const routes = [
  {
    path: '/',
    name: 'splash',
    component: SplashView
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView
  },
  {
    path: '/cadastro',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/recuperar-senha',
    name: 'forgot',
    component: ForgotPasswordView
  },
  {
    path: '/verificar-email',
    name: 'confirmCode',
    component: ConfirmCodeView
  },
  {
    path: '/selecionar-registro',
    name: 'selectRegister',
    component: SelectRegister
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
