<template>
    <section class="auth-register">
        <span class="loading hide">
            <div class="spinner"></div>
        </span>

        <div class="arrow-back" @click="$router.push('/auth')">
            <img src="@/assets/images/arrow_back.png" alt="">
        </div>
        <div class="login-register register">
            <div class="auth-login-header">
                <label class="header-title">Recuperar senha</label>
                <p class="header-description">Precisamos identificar você, forneça seu e-mail!</p>
            </div>
            <div class="box-form">
                <div class="group-input">
                    <input class="input" id="email" type="email" required>
                    <label class="text" for="email">E-mail</label>
                </div>
                <span class="button-all button-primary mt-10">Recuperar senha</span>
            </div>
            <div class="auth-login-social mt-20">

                <div class="register">
                    Lembrou sua senha?
                    <router-link class="button-link" to="/auth">Faça login</router-link>
                </div>
            </div>   
        </div>
    </section>
</template>

<script>
export default {
    data () {
        return {
            showPassword: false,
            showRePassword: false,
            inputType: 'password',
            inputTypeRe: 'password'
        }
    },
    methods: {
        toggleVisibility(type) {

            if(type == 1){
                this.showPassword = !this.showPassword;
                this.inputType = this.showPassword ? 'text' : 'password'

                return;
            } 
            this.showRePassword = !this.showRePassword;
            this.inputReType = this.showRePassword ? 'text' : 'password';
        },
    }
}
</script>
